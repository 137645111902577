var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app flex-row align-items-center" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "justify-content-center" },
          [
            _c(
              "b-col",
              { attrs: { md: "6" } },
              [
                _c("div", { staticClass: "clearfix" }, [
                  _c("h1", { staticClass: "float-left display-3 mr-4" }, [
                    _vm._v("500"),
                  ]),
                  _c("h4", { staticClass: "pt-3" }, [
                    _vm._v(
                      _vm._s(_vm.FormMSG(1, "Houston, we have a problem")) + "!"
                    ),
                  ]),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(
                      _vm._s(
                        _vm.FormMSG(
                          2,
                          "The page you are looking for is temporarily unavailable"
                        )
                      ) + "."
                    ),
                  ]),
                ]),
                _c(
                  "b-input-group",
                  [
                    _c(
                      "b-input-group-prepend",
                      [
                        _c("b-input-group-text", [
                          _c("i", { staticClass: "fa fa-search" }),
                        ]),
                      ],
                      1
                    ),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "prependedInput",
                        size: "16",
                        type: "text",
                        placeholder: _vm.FormMSG(
                          3,
                          "What are you looking for?"
                        ),
                      },
                    }),
                    _c(
                      "b-input-group-append",
                      [
                        _c("b-button", { attrs: { variant: "info" } }, [
                          _vm._v(_vm._s(_vm.FormMSG(4, "Search"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }