<template>
	<div class="app flex-row align-items-center">
		<div class="container">
			<b-row class="justify-content-center">
				<b-col md="6">
					<div class="clearfix">
						<h1 class="float-left display-3 mr-4">500</h1>
						<h4 class="pt-3">{{ FormMSG(1, 'Houston, we have a problem') }}!</h4>
						<p class="text-muted">{{ FormMSG(2, 'The page you are looking for is temporarily unavailable') }}.</p>
					</div>
					<b-input-group>
						<b-input-group-prepend>
							<b-input-group-text>
								<i class="fa fa-search"></i>
							</b-input-group-text>
						</b-input-group-prepend>
						<input id="prependedInput" class="form-control" size="16" type="text" :placeholder="FormMSG(3, 'What are you looking for?')" />
						<b-input-group-append>
							<b-button variant="info">{{ FormMSG(4, 'Search') }}</b-button>
						</b-input-group-append>
					</b-input-group>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
export default {
	name: 'Page500',
	mixins: [languageMessages]
};
</script>
